import React from "react";
import { Header } from "../header";
import { Footer } from "../footer";
import { TermsAndConditions } from "../../module/terms&Cond/termsContent";


export const Terms_Condition = ({getnamePackages,packages,category}) => {
  return (
    <div>
      
      <div className="fixed w-full z-50 bg-white">
      <Header getnamePackages={getnamePackages} packages={packages} category={category}/>
      </div>
      <div className="absolute top-20 mt-6 w-full text-white bg-white">
        <TermsAndConditions />
        <Footer getnamePackages={getnamePackages} packages={packages}/>
      </div>
    </div>
  );
};
