import React from "react";
import { Header } from "../header";
import { Footer } from "../footer";
import { PrivacyContent } from "../../module/privacyPage/privacy-content";
import { Helmet } from "react-helmet";


export const PrivacyPolicy = ({getnamePackages}) => {
  return (
    <div>
      <Helmet>
        <title>
          Explore New York Iconic Cruises Data Protection & Security
        </title>
        <meta
          name="description"
          content="Understand how we collect, use, and protect your data. Our privacy policy outlines our commitment to safeguarding your personal information. Get in touch!"
        />
        
      </Helmet>
      <div className="fixed w-full z-50 bg-white">
        <Header getnamePackages={getnamePackages} />
      </div>
      <div className="absolute top-20 mt-6 w-full text-white bg-white">
        <PrivacyContent />
        <Footer />
      </div>
    </div>
  );
};
