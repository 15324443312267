import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  BannerSection,
  DoubleDacker,
  IcoicPackages,
  Pricing,
  SingleBuspricing,
  WhyChoose,
} from "../../module/home";
import { InfoSection } from "../../module/home/infoSection";
import { Footer, Header } from "../../layout";
import { MainPageModal } from "../modal/mainpage";

export const Home = ({ getnamePackages, packages, category }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("https://api.nyiconictours.com/homepage/all")
      .then((response) => response.json())
      .then((data) => setData(data?.data));
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          NYC Sightseeing Bus Tour & Statue of Liberty Boat Tour| All City Pass
        </title>
        <meta
          name="description"
          content="At New York Iconic Cruises we offer exciting New York City tours: hop on hop off tours, night tours and boat tours. Book now for an unforgettable adventure in the city!"
        />
        <meta
          name="keywords"
          content="Bus Trips to New York City, New York city tours
New York tours, bus tours NYC, New York attractions, NYC city bus tour, bus trips to New York city"
        />
      </Helmet>
      <div className="fixed w-full z-50 bg-white">
        <Header
          getnamePackages={getnamePackages}
          packages={packages}
          category={category}
        />
      </div>
      <div className="absolute top-20 mt-6 w-full text-white">
        <BannerSection homeData={data} />
        <SingleBuspricing
          getnamePackages={getnamePackages}
          packages={packages}
          category={category}
        />
        {/* <DoubleDacker getnamePackages={getnamePackages} />
        <IcoicPackages getnamePackages={getnamePackages} /> */}
        <WhyChoose homeData={data} />
        <Pricing homeData={data} packages={packages} />
        <InfoSection homeData={data} />
        <Footer getnamePackages={getnamePackages} packages={packages} />

        <MainPageModal />
      </div>
    </div>
  );
};
