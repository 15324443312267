import React from 'react'
import { Footer, Header } from "../../layout";
import { TourSection } from '../../module/tour';
import { Helmet } from "react-helmet";

export const Tour = ({getnamePackages,packages,category}) => {
  return (
    <div>
      <Helmet>
        <title>
          New York Iconic Cruises Tour | Book Your Experience Today
        </title>
        <meta
          name="description"
          content="Discover New York City with flexible hop on hop off day & night city bus tours. Enjoy NYC's iconic sights, guided tours, and the best landmark views. Book now!"
        />
        <meta
          name="keywords"
          content="Bus Tour NYC, Night Bus Tour NYC, Hop On Hop Off Bus NYC, New York City Night Bus Tour, Hop On Hop Off Bus Tour NYC, Hop On Hop Off Bus Tour, NYC Guided Tours, New York City Bus Tour, Night City Tour, New York Night Bus Tour, New York City Hop On Hop Off Bus"
        />
      </Helmet>
       <div className="">
      <div className="fixed w-full z-50 bg-white">
      <Header getnamePackages={getnamePackages} packages={packages} category={category}/>

      </div>
      <div className="absolute top-20 mt-6 w-full text-white">
       <TourSection  getnamePackages={getnamePackages} packages={packages}/>
       <Footer getnamePackages={getnamePackages} packages={packages}/>
    </div>
      </div> 
    </div>
  )
}

